window.addEventListener('load', function () {
    var warehouse = new Vue({
        el: '#formWarehouse',
        data: {
            sent: false,
            errors: '',
            formName: {
                name: 'formWarehouse',
                description: 'Заявка на страхование товарных запасов'
            },
            form: {
                organizationName: {
                    "f-label": "Наименование компании *", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": true,
                    "f-style": {}
                },
                delegateName: {
                    "f-label": "ФИО представителя",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                phone: {
                    "f-label": "Введите номер телефона для обратной связи *",
                    "f-type": "phone",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {},
                    "f-regexp": "^\\+7\\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2}$"
                },
                email: {
                    "f-label": "Введите эл.почту для обратной связи *",
                    "f-type": "email",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": true,
                    "f-style": {}
                },
                wareProduct: {
                    "f-label": "Вид товарных запасов",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "productDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                wareProductDescription: {
                    "f-label": "Дополнительная информация",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "Добавьте дополнительное описание при необходимости...",
                    "f-required": false,
                    "f-style": {}
                },
                wareBusiness: {
                    "f-label": "Осуществляемая производственная деятельность",
                    "f-type": "select",
                    "f-condition": true,
                    "f-value": "",
                    "f-dic": "businessDic",
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                wareBusinessDescription: {
                    "f-label": "Дополнительная информация",
                    "f-type": "text",
                    "f-condition": false,
                    "f-value": "",
                    "f-placeholder": "Добавьте дополнительное описание при необходимости...",
                    "f-required": false,
                    "f-style": {}
                },
                wareBusinessOptions: {
                    "f-label": "Описание производственной деятельности",
                    "f-type": "multiselect",
                    "f-condition": false,
                    "f-value": [],
                    "f-dic": [],
                    "f-plain-value" : "",
                    "f-required": false,
                    "f-style": {}
                },
                averageSum: {
                    "f-label": "Стоимость товарных запасов: средняя загрузка (млн.рублей)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                maxSum: {
                    "f-label": "Стоимость товарных запасов: максимальная загрузка (млн.рублей)",
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-required": false,
                    "f-style": {}
                },
                city: {
                    "f-label": "Месторасположение товарных запасов (указать город)", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                },
                companyAge: {
                    "f-label": "Сколько лет Ваша компания осуществляет свою деятельность", 
                    "f-type": "text",
                    "f-condition": true,
                    "f-value": "",
                    "f-placeholder": "",
                    "f-required": false,
                    "f-style": {}
                }
            },
            dictionaries: {
                productDic: [
                    {name: "raw", description: "сырье"},
                    {name: "semi", description: "полуфабрикаты"},
                    {name: "prepared", description: "готовая продукция"}
                ],
                businessDic: [
                    {name: "store", description: "услуги по хранению товарных запасов"},
                    {name: "sale", description: "торговля"},
                    {name: "production", description: "производство"},
                    {name: "popshop", description: "ломбарды"},
                    {name: "other", description: "иное"}
                ],
                storeDic: [
                    {name: "alcohol", description: "алкогольная продукция"},
                    {name: "computerMobile", description: "компьютерная техника и/или оборудование для мобильной связи"},
                    {name: "food", description: "продовольственные товары"},
                    {name: "household", description: "хозяйственные товары"},
                    {name: "appliances", description: "бытовая техника"},
                    {name: "construction", description: "строительные товары"},
                    {name: "medicine", description: "медикаменты"},
                    {name: "car", description: "автомобильные запчасти"},
                    {name: "extraConditions", description: "товары, требующие особых температурных условий хранения"},
                    {name: "other", description: "иное"}
                ],
                saleDic: [
                    {name: "alcohol", description: "алкогольная продукция"},
                    {name: "computerMobile", description: "компьютерная техника и/или оборудование для мобильной связи"},
                    {name: "food", description: "продовольственные товары"},
                    {name: "household", description: "хозяйственные товары"},
                    {name: "appliances", description: "бытовая техника"},
                    {name: "construction", description: "строительные товары"},
                    {name: "jewelry", description: "ювелирные изделия"},
                    {name: "medicine", description: "медикаменты"},
                    {name: "car", description: "автомобильные запчасти"},
                    {name: "extraConditions", description: "товары, требующие особых температурных условий хранения"},
                    {name: "other", description: "иное"}
                ],
                productionDic: [
                    {name: "lumber", description: "пиломатериалы"},
                    {name: "extraConditions", description: "товары, требующие особых температурных условий хранения"},
                    {name: "other", description: "иное"}
                ],
                popshopDic: [
                    {name: "jewelry", description: "ювелирные изделия"},
                    {name: "appliances", description: "бытовая техника"},
                    {name: "transport", description: "транспортные средства"},
                    {name: "other", description: "иное"}
                ]
            }
        },
        methods: {
            getBusinessOptions() {
                return this.dictionaries[this.form.wareBusiness + 'Dic'];
            },
            refreshForm() {
                for (var key in this.form) {
                    this.form[key]['f-value'] = '';
                }
            },
            onSubmit(evt) {
                evt.preventDefault();

                this.errors = "";
                let errorsCnt = validateForm(this.form);
                if (errorsCnt > 0) {
                    this.errors = "Необходимо корректно заполнить все поля, отмеченные звездочкой (*)";
                }

                if (errorsCnt == 0) {

                    let mailMessage = constructMailMessage(this.form, this.formName.description);

                    const emailOptions = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        data: {msg: mailMessage},
                        url: '/local/form/submit-msg-form.php',
                    };

                    this.$axios(emailOptions)
                        .then(res => { 
                            // console.log('msg was sent ' + mailMessage);
                            this.sent = true; 
                            this.refreshForm();
                            $.fancybox.close({src: '#' + this.form.name});
                            $.fancybox.open('<div  id="thanks" class="popup ths"> <div class="ths-box"> <div class="ths-marg"> <p class="ths-t1">заявка принята</p> <p class="ths-t2">Мы свяжемся с вами <br>в ближайшее время</p> </div> </div> </div>');
                        })
                        .catch(function (error) {
                            console.log(error);
                            $.fancybox.open('<div class="message"><h2>Ошибка</h2></div>');
                        });
                }
            }
        },
        computed: {
            watchWareProduct() {
                return this.form.wareProduct['f-value'];
            },
            watchWareBusiness() {
                return this.form.wareBusiness['f-value'];
            },
            watchWareBusinessOptions() {
                return this.form.wareBusinessOptions['f-value'];
            }
        },
        watch: {
            watchWareProduct: function() {
                var vm = this;
                this.form.wareProductDescription['f-value'] = '';
                this.form.wareProductDescription['f-condition'] = this.form.wareProduct['f-value'] !== '';
                try {
                    this.form.wareProduct['f-plain-value'] = this.dictionaries[this.form.wareProduct['f-dic']] ? 
                        this.dictionaries[this.form.wareProduct['f-dic']].find(function(dic){
                            return dic.name === vm.form.wareProduct['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchWareBusiness: function() {
                var vm = this;
                this.form.wareBusinessDescription['f-value'] = '';
                this.form.wareBusinessDescription['f-condition'] = this.form.wareBusiness['f-value'] !== '';

                this.form.wareBusinessOptions['f-value'] = [];
                this.form.wareBusinessOptions['f-condition'] = this.form.wareBusiness['f-value'] !== '' && this.form.wareBusiness['f-value'] !== 'other';
                this.form.wareBusinessOptions['f-dic'] = this.form.wareBusiness['f-value'] + 'Dic';
                try {
                    this.form.wareBusiness['f-plain-value'] = this.dictionaries[this.form.wareBusiness['f-dic']] ? 
                        this.dictionaries[this.form.wareBusiness['f-dic']].find(function(dic){
                            return dic.name === vm.form.wareBusiness['f-value'] ;
                        }).description : '';
                } catch(ignoredException) {}
            },
            watchWareBusinessOptions: function() {
                var vm = this;
                try {
                    this.form.wareBusinessOptions['f-plain-value'] = this.form.wareBusiness['f-value'] && 
                        this.dictionaries[this.form.wareBusiness['f-value'] + 'Dic'] ? 
                            this.form.wareBusinessOptions['f-value'].map(function(bOption) {
                                var dicOption = vm.dictionaries[vm.form.wareBusiness['f-value'] + 'Dic'].find(function(dic){
                                    return dic.name === bOption.name;
                                });
                                return dicOption.description;
                            }).join('; ') : '';
                } catch(ignoredException) {}
            }
        }
    });
});